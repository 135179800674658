import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Typography from '../../components/Common/Typography'
import List from '../../components/Common/List'

import * as s from '../../pages/news.module.scss'

const SredaObshihDannyhDlyaCifrovizaciiStroitelstva: React.FC = () => {
  return (
    <>
      <SEO
        title="Среда общих данных - первый шаг к цифровизации строительства"
        description="Среда общих данных для управления строительными проектами это пространство, которое может объединить участников строительного процесса и актуальную документацию в одном месте, с возможностью просмотра файлов, выдачи замечаний и согласований"
      />
      <NewsSectionBlock date="20.08.2024">
        <Typography variant="h1" color="blue">
          Среда общих данных - первый шаг к цифровизации строительства
        </Typography>
        <Typography variant="h2" color="blue">
          Что такое СОД?
        </Typography>
        <Typography variant="body1">
          Среда общих данных (СОД) представляет собой облачную систему
          юридически значимого инженерного документооборота в строительстве. Это
          место, где аккумулируется информация, с гибко настраиваемым
          разграничением прав доступа, согласованиями, поддержкой версионности
          файлов, их просмотра и выдачи замечаний к элементам модели или марками
          на листах.
        </Typography>
        <Typography variant="body1">
          СОД полезна для реализации девелоперских проектов на всех этапах
          жизненного цикла объекта, начиная с проектирования и заканчивая
          строительством с последующей эксплуатацией. Это достигается путем
          упорядочения обмена информацией, обеспечения доступа к обновляемым
          проектным данным для всех заинтересованных сторон, распределения
          заданий среди сотрудников и подрядчиков, а также мониторинга их
          исполнения в рамках единой информационной среды.
        </Typography>
        <ol className={cn(s.textLists, s.decimal)}>
          <li>
            <Typography variant="body1">
              <b>Планирование.</b> На этом этапе, как правило технический
              заказчик создает единое пространство для хранения всей
              предпроектной документации, согласовываются и подписываются
              необходимые документы с подрядчиками, разрабатываются и
              утверждаются мастер-планы, организовывается доступ участников ко
              всем полученным ТУ, инженерным и другим изысканиям.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <b>Проектирование.</b> Предоставление доступа к проектной
              документации всем заинтересованным участникам: подготовка
              информации, передача ее в экспертизу и для проведения тендеров,
              отслеживание изменений, выдача замечаний и согласование проектных
              решений, передача рабочей документации “в производство работ”.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <b>Строительство.</b> Полный доступ к согласованной проектной
              документации, получения актуальной информации со строительной
              площадки, выдача предписаний и замечаний, подготовка, согласование
              и хранение исполнительной документации. Согласование и подписание
              Актов и КСок с помощью ЭЦП.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <b>Эксплуатация.</b> Доступ эксплуатирующей компании к
              исполнительной документации, инженерным данным, характеристикам
              инженерных сооружений и порядке их проверки и эксплуатации.
              Планирование и согласование этапов ремонта, модернизации и
              реконструкции.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <b>Ликвидация.</b> Разработка и согласование планов по остановке
              производства, разборке здания и утилизации отходов, а также его
              продажа или перепрофилирование в коммерческом плане.
            </Typography>
          </li>
        </ol>
        <Typography variant="body1">
          Впервые термин СОД как Common Data Environment (CDE) зафиксирован в
          стандарте BS1192-2007, в Российской Федерации данный термин
          урегулирован стандартом ГОСТ Р 10.0.01-2018. Его определяют как
          программный комплекс для управления, хранения и обмена данными
          информационных моделей на всех этапах жизненного цикла. В стандарте СП
          471.1325800.2019 СОД описывается как комплекс программно-технических
          средств, который служит единым источником данных и обеспечивает
          совместное использование информации всеми участниками
          инвестиционно-строительного проекта.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../../assets/images/news/sreda-obshih-dannyh-dlya-cifrovizacii-stroitelstva/image-1.jpg"
            alt="Что такое СОД?"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Преимущества СОД
        </Typography>
        <Typography variant="body1">
          СОД позволяет всем участникам строительного процесса более эффективно
          взаимодействовать друг с другом. Вся актуальная информация по объекту
          строительства находится в одном месте. С помощью решений SIGNAL
          участники получают доступ к тем данным, которые им нужны. Организация,
          работающая на платформе, использует собственный хаб на серверах
          Яндекса или на своем сервере, доступ к которому осуществляется по
          электронной почте. Каждая компания, с помощью встроенной платформы для
          администрирования, может контролировать доступ к лицензиям и проектам,
          а также к информации, которой они делятся с другими участниками
          проекта.
        </Typography>
        <Typography variant="body1">
          Система позволяет просматривать более 50 форматов файлов, в т.ч.
          BIM-модели, делая BIM доступным инструментом для всех участников
          строительства, которые не разрабатывают информационные модели.
        </Typography>
        <Typography variant="body1">
          СОД делает все рабочие процессы прозрачными, улучшая принятие решений
          и обмен информацией между всеми специалистами. Использование СОДа
          позволяет упростить доступ к документации и ее контролю,
          оптимизировать процессы, уменьшить количество ошибок и ускорить их
          разрешение.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../../assets/images/news/sreda-obshih-dannyh-dlya-cifrovizacii-stroitelstva/image-2.jpg"
            alt="Преимущества СОД"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Среда общих данных – базовое условие цифровизации стройки
        </Typography>
        <Typography variant="body1">
          Среда общих данных играет ключевую роль в цифровизации строительной
          отрасли, предоставляя интегрированную платформу для хранения,
          управления и обмена информацией на протяжении жизненного цикла цикла
          стройки. СОД – это основа для внедрения цифровых решений в
          строительстве и использования таких методологий, как Building
          Information Modeling (BIM) и Digital Twins.
        </Typography>
        <Typography variant="h3">ЗАЧЕМ СТРОИТЕЛЬНОЙ ОТРАСЛИ СОД</Typography>
        <List>
          <li>
            <b>Централизованная база данных:</b> В СОДе собирается вся
            информация о проекте, включая чертежи, модели, спецификации,
            контракты и т.д. Это позволяет участникам проекта легко получать
            доступ к актуальной информации.
          </li>
          <li>
            <b>Совместная работа и координация:</b> Цифровизация строительства
            требует совместной работы между различными участниками проекта. СОД
            позволяет оперативно обмениваться информацией и координировать
            действия между всеми сторонами.
          </li>
          <li>
            <b>Управление версиями и изменениями:</b> В среде общих данных
            удобно следить за изменениями в моделях и документах, управлять
            версиями и контролировать процесс изменения информации. Это снижает
            риски ошибок и несоответствий.
          </li>
          <li>
            <b>Автоматизация процессов:</b> Цифровизация строительства часто
            включает в себя автоматизацию процессов, таких как уведомления о
            сроках, управление задачами и автоматическая генерация отчетов.
            Среда общих данных облегчает внедрение таких автоматизированных
            процессов.
          </li>
          <li>
            <b>Аналитика и отчетность:</b> Среда общих данных может
            предоставлять инструменты для анализа данных, создания отчетов и
            мониторинга производительности проекта. Это помогает улучшить
            управленческие решения и оптимизировать процессы.
          </li>
          <li>
            <b>СОД помогает соблюдать стандарты и нормативы:</b> Цифровизация
            строительства часто связана с соблюдением стандартов и нормативов,
            таких как ISO 19650 / ГОСТ Р 10.0.01-2018 для управления информацией
            в BIM или СП 471.1325800.2019.
          </li>
        </List>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../../assets/images/news/sreda-obshih-dannyh-dlya-cifrovizacii-stroitelstva/image-3.jpg"
            alt="Среда общих данных – базовое условие цифровизации стройки"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          SIGNAL DOCS: Среда общих данных для управления строительными проектами
        </Typography>
        <Typography variant="body1">
          SIGNAL DOCS – цифровая платформа для управления документами и
          информацией в строительной отрасли. Она предоставляет возможности для
          хранения, организации, обмена и управления проектной документацией и
          данными на протяжении жизненного цикла строительного проекта.
        </Typography>
        <Typography variant="body1">
          SIGNAL DOCS был разработан как решение для импортозамещения ушедших из
          России Autodesk BIM 360 / Autodesk Construction Cloud, воспроизведя
          функционал этих продуктов внутри платформы с дополнительными
          возможностями с упором на специфику работы на российском рынке.
        </Typography>
        <Typography
          variant="body1"
          position="center"
          style={{ fontWeight: 700 }}
        >
          SIGNAL DOCS – облачное программное обеспечение для ведения
          технического юридически значимого документооборота.
        </Typography>
        <Typography variant="body1">
          Основные функциональности SIGNAL DOCS включают в себя:
        </Typography>
        <List>
          <li>Осуществление электронного документооборота компании</li>
          <li>Администрирование</li>
          <li>
            Просмотр 2D-документации и 3D-моделей, поддержка более 25 форматов
          </li>
          <li>Выдача замечаний и маркапов</li>
          <li>Согласование проектной документации</li>
          <li>Подписание ЭЦП</li>
          <li>Простановка QR-кодов и штампов в производство работ</li>
          <li>Интеграция с Google - документами и таблицами</li>
          <li>Отслеживание и контроль (сравнение) версий файлов</li>
          <li>Дашборды аналитики и отчетности </li>
        </List>
        <Typography variant="h2" color="blue">
          Заключение
        </Typography>
        <Typography variant="body1">
          Сегодня использование СОД становится обязательным условием для
          эффективного управления проектами в проектировании и строительстве.
          SIGNAL DOCS предоставляет удобную платформу для хранения, управления и
          обмена данными между участниками проекта.
        </Typography>
        <Typography variant="body1">
          Использование SIGNAL DOCS обеспечивает прозрачность и отслеживаемость
          всех процессов, снижает риски ошибок и дублирования работы, повышает
          эффективность рабочих процессов и качество взаимодействия между
          участниками, помогает соблюдать отраслевые стандарты.
        </Typography>
        <Typography variant="body1">
          Благодаря SIGNAL DOCS управление проектом становится более
          структурированным, аналитичным и прозрачным, что способствует
          улучшению качества строительства, сокращению затрат и соблюдению
          сроков.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default SredaObshihDannyhDlyaCifrovizaciiStroitelstva
